export default {
  props: {
    query: Object,
    variables: Object,
    update: Function,
  },

  data() {
    return {
      response: null,
      loading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    data() {
      const props = Object.fromEntries(
        Object.entries(this.$props).filter(
          ([key]) => !["query", "variables", "update"].includes(key)
        )
      );
      return Object.assign({}, props, this.response);
    },
  },

  methods: {
    async fetch() {
      if (!this.query) return;
      this.loading = true;
      this.response = await this.$apollo
        .query({
          query: this.query,
          variables: this.variables,
        })
        .then(({ data }) => {
          this.loading = false;
          return this.update(data);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
  },
};
